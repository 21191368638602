import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ab77d3c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

import { Plus,Close } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
import { reactive,ref,getCurrentInstance, watch} from 'vue'

export default {
  props: {
  defaultImg:String,
  name:String,
  file:Object,
  url:String,//图片地址
  showClose:Boolean,//展示关闭按钮
},
  emits: ['getLicense'],
  setup(__props, { emit: emits }) {

const props = __props

const {$filters} = getCurrentInstance().proxy
const {concatImg} = $filters
const form = reactive({
  photo:'',
});

let photoRef = ref(null)
let imageUrl = ref()

// 切换图片
const handleAvatarChange = (res, file)=>{
  imageUrl.value = URL.createObjectURL(res.raw) 
  emits('getLicense',res.raw)
}
// 上传前拦截 图片格式和大小
const beforePhotoUpload=(file)=>{
  const type = ["image/jpeg", "image/jpg", "image/png", "image/svg","image/gif"];
  const isJPG = type.includes(file.type);
  const isLt1M = file.size / 1024 / 1024 < 1;
  if (!isJPG) {
    ElMessage({
      message: "图片格式错误",
      type: "success",
    });
  }
  if (!isLt1M) {
    ElMessage({
      message: "上传图片不能超过" + 2 + "M",
      type: "success",
    });
  }
  return isJPG && isLt1M;
}
// 关闭
const closeHandler = ()=>{
  imageUrl.value = ''
  emits('getLicense','')
}
// 赋空
watch(()=>props.file,()=>{
  form.photo = props.file
  if(!props.file)imageUrl.value = ''
  
},{deep:true})
// 上传图片成功返回的数据
const uploadPhotoSuccess = (res, file)=>{
  form.photo = res.data.path
}

return (_ctx, _cache) => {
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_upload = _resolveComponent("el-upload")

  return (_openBlock(), _createBlock(_component_el_upload, {
    action: "https://jsonplaceholder.typicode.com/posts/",
    "on-change": handleAvatarChange,
    "show-file-list": false,
    ref_key: "photoRef",
    ref: photoRef,
    "auto-upload": false,
    "on-success": uploadPhotoSuccess
  }, {
    default: _withCtx(() => [
      (!__props.defaultImg && !_unref(imageUrl) && !__props.url)
        ? (_openBlock(), _createBlock(_component_el_icon, {
            key: 0,
            class: "avatar-uploader-icon"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(Plus))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_unref(imageUrl) && __props.defaultImg)
        ? (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: __props.name=='online'?_unref(concatImg)(__props.defaultImg):require(`../assets/${__props.defaultImg}`),
            alt: "",
            class: "upload_img"
          }, null, 8, _hoisted_1))
        : _createCommentVNode("", true),
      (_unref(imageUrl) && __props.showClose)
        ? (_openBlock(), _createBlock(_component_el_icon, {
            key: 2,
            class: "close",
            onClick: _cache[0] || (_cache[0] = _withModifiers($event => (closeHandler()), ["stop"]))
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(Close))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_unref(imageUrl) || __props.url)
        ? (_openBlock(), _createElementBlock("img", {
            key: 3,
            src: _unref(imageUrl) || __props.url,
            alt: "",
            class: "upload_img"
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "content")
    ]),
    _: 3
  }, 512))
}
}

}